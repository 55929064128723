import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getDepartments(params) {
  return request({
    url: ENDPOINT.DEPARTMENT,
    method: 'get',
    params
  })
}

export function createDepartment(data) {
  return request({
    url: ENDPOINT.DEPARTMENT,
    method: 'post',
    data
  })
}

export function updateDepartment(data) {
  return request({
    url: ENDPOINT.DEPARTMENT,
    method: 'patch',
    data
  })
}

export function deleteDepartment(params) {
  return request({
    url: ENDPOINT.DEPARTMENT,
    method: 'delete',
    params
  })
}
