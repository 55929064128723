<template>
  <el-dialog
    top="80px"
    width="600px"
    class="package-form"
    :title="!department ? 'Thêm mới phòng ban' : 'Chỉnh sửa phòng ban'"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" label-position="top" :rules="rules">
      <el-form-item label="Tên phòng ban" prop="name">
        <el-input v-model="form.name" placeholder="Nhập vào tên phòng ban"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleUpdate">{{ !department ? 'Tạo' : 'Cập nhật' }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createDepartment, updateDepartment } from '@/services/department'
export default {
  name: 'UpdateDepartment',
  props: {
    department: Object,
    visible: Boolean
  },
  data() {
    return {
      callingAPI: false,
      form: {
        name: this.department ? this.department.name : ''
      },
      rules: {
        name: [
          { required: true, message: 'Tên phòng ban không được để trống', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    dataInput() {
      const data = {
        name: this.form.name,
        locale: this.language
      }
      if (this.department) {
        data.id = this.department.id
      }
      return data
    }
  },
  methods: {
    handleUpdate() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.department) {
            createDepartment(this.dataInput, this.type).then(() => {
              this.$notify.success({
                title: 'Thành công',
                message: 'Tạo mới phòng ban thành công'
              })
              this.$emit('reload')
              this.callingAPI = false
            }).catch(() => {
              this.callingAPI = false
            })
          } else {
            updateDepartment(this.dataInput, this.type).then(() => {
              this.$notify.success({
                title: 'Thành công',
                message: 'Cập nhật phòng ban thành công'
              })
              this.$emit('reload')
              this.callingAPI = false
            }).catch(() => {
              this.callingAPI = false
            })
          }
        }
      })
    },
    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
